<template>
	<v-text-field
		:label="input.label"
		:type="input.input_type"
		:value="header.value"
		:disabled="header.disabled"
		@input="(val) => setValue(val)"
		hide-details
		outlined
	></v-text-field>
</template>
<script>
export default {
	name: 'CTextField',
	props: {
		input: Object,
	},
	data: () => ({
		model: null,
	}),
	computed: {
		header() {
			return this.$store.getters['inspection/getHeaderById'](this.input.id) || null;
		},
	},
	methods: {
		setValue(value) {
			this.$store.dispatch('inspection/updateInputHeader', {
				value,
				id: this.input.id,
			});
		},
	},
};
</script>
